import {
  BusFilledSVG,
  BusSVG,
  TrainFilledSVG,
  TrainSVG,
} from 'assets/airportTransfers/searchUnitSVGs';

export const STATION_TYPE_SVG_MAP = {
  TRAIN_STATION: <TrainSVG />,
  BUS_STOP: <BusSVG />,
};

export const STATION_TYPE_SVG_MAP_FILLED = {
  TRAIN_STATION: <TrainFilledSVG />,
  BUS_STOP: <BusFilledSVG />,
};
