/* eslint-disable react/display-name */
import { forwardRef, SVGProps } from 'react';

export const WorldMapSVGDesktop = forwardRef(function SVG(
  props: SVGProps<SVGSVGElement>,
  ref: any
) {
  return (
    <svg
      width="775"
      height="340"
      viewBox="0 0 775 340"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
      ref={ref}
      id="worldMapSVG"
    >
      <rect
        width="774.766"
        height="339.472"
        fill="url(#pattern0_14023_93167)"
      />
      <circle cx="186.708" cy="141.633" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="186.708"
        cy="141.633"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="162.185" cy="135.274" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="162.185"
        cy="135.274"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <ellipse
        cx="360.5"
        cy="49.85"
        rx="9.5"
        ry="2.85"
        fill="#DDBBFF"
        fillOpacity="0.52"
        id="LONDON"
      />
      <circle cx="355.649" cy="85.3208" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="355.649"
        cy="85.3208"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="371.09" cy="78.9615" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="371.09"
        cy="78.9615"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="415.596" cy="85.3208" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="415.596"
        cy="85.3208"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="427.403" cy="81.688" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="427.403"
        cy="81.688"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <ellipse
        cx="498.5"
        cy="106.85"
        rx="9.5"
        ry="2.85"
        fill="#DDBBFF"
        fillOpacity="0.52"
        id="DUBAI"
      />
      <circle cx="445.235" cy="94.2349" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="445.235"
        cy="94.2349"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <ellipse
        cx="714.5"
        cy="141.85"
        rx="9.5"
        ry="2.85"
        fill="#DDBBFF"
        fillOpacity="0.52"
        id="TOKYO"
      />
      <circle cx="624.501" cy="140.719" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="624.501"
        cy="140.719"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="611.785" cy="183.415" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="611.785"
        cy="183.415"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="611.785" cy="157.977" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="611.785"
        cy="157.977"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="736.22" cy="217.016" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="736.22"
        cy="217.016"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="364.732" cy="41.7193" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="364.732"
        cy="41.7193"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="399.247" cy="66.2427" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="399.247"
        cy="66.2427"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="382.897" cy="61.7036" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="382.897"
        cy="61.7036"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="407.421" cy="55.3443" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="407.421"
        cy="55.3443"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="394.705" cy="42.6255" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="394.705"
        cy="42.6255"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="200.333" cy="85.3208" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="200.333"
        cy="85.3208"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <circle cx="737.22" cy="280.016" r="3.23489" fill="#D7AFFF" />
      <circle
        cx="737.22"
        cy="280.016"
        r="4.31319"
        stroke="#E0C7FF"
        strokeOpacity="0.43"
        strokeWidth="2.15659"
      />
      <ellipse
        cx="217.5"
        cy="89.85"
        rx="9.5"
        ry="2.85"
        fill="#DDBBFF"
        fillOpacity="0.52"
        id="NEW_YORK"
      />
      <defs>
        <pattern
          id="pattern0_14023_93167"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_1_369"
            transform="scale(0.000528541 0.00120627)"
          />
        </pattern>
        <image
          id="image0_1_369"
          width="1892"
          height="829"
          xlinkHref="https://cdn-imgix.headout.com/airport-transfer-mbs/images/worldmap-base-dweb.png"
        />
      </defs>
    </svg>
  );
});
