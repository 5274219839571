export const ShieldOutlineSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99967 12.8385C6.99967 12.8385 11.6663 10.5052 11.6663 7.00521V2.92188L6.99967 1.17188L2.33301 2.92188V7.00521C2.33301 10.5052 6.99967 12.8385 6.99967 12.8385Z"
      stroke="#F1E5FF"
      strokeWidth="0.875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ThumbsUpOutlineSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13323_60849)">
      <path
        d="M4.08366 12.8385H2.33366C2.02424 12.8385 1.72749 12.7156 1.5087 12.4968C1.28991 12.278 1.16699 11.9813 1.16699 11.6719V7.58854C1.16699 7.27912 1.28991 6.98238 1.5087 6.76358C1.72749 6.54479 2.02424 6.42187 2.33366 6.42187H4.08366M8.16699 5.25521V2.92187C8.16699 2.45775 7.98262 2.01263 7.65443 1.68444C7.32624 1.35625 6.88112 1.17188 6.41699 1.17188L4.08366 6.42187V12.8385H10.6637C10.945 12.8417 11.218 12.7431 11.4324 12.5609C11.6468 12.3786 11.7881 12.1251 11.8303 11.8469L12.6353 6.59687C12.6607 6.42967 12.6494 6.25894 12.6023 6.09652C12.5551 5.9341 12.4732 5.78388 12.3622 5.65626C12.2513 5.52864 12.1139 5.42667 11.9596 5.35742C11.8053 5.28817 11.6378 5.25329 11.4687 5.25521H8.16699Z"
        stroke="#F1E5FF"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13323_60849">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const DoorOutlineSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 6.125C1.75 3.2255 4.1005 0.875 7 0.875V0.875C9.89949 0.875 12.25 3.22551 12.25 6.125V13.125H1.75V6.125Z"
      stroke="#F1E5FF"
      strokeWidth="0.875"
      strokeLinejoin="round"
    />
    <path d="M7 0.875V13.125" stroke="#F1E5FF" strokeWidth="0.875" />
    <path
      d="M8.75 6.5625V7.875"
      stroke="#F1E5FF"
      strokeWidth="0.875"
      strokeLinecap="round"
    />
    <path
      d="M5.25 6.5625V7.875"
      stroke="#F1E5FF"
      strokeWidth="0.875"
      strokeLinecap="round"
    />
  </svg>
);

export const SparkleSVG = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_13323_38433)">
      <path
        d="M12.1073 9.71406C12.1081 9.85977 12.0663 10.0022 11.9875 10.1217C11.9087 10.2412 11.7969 10.3319 11.6674 10.3814L9.48689 11.2302L8.68478 13.5356C8.63728 13.6722 8.55134 13.79 8.43856 13.8732C8.32577 13.9565 8.19158 14.0011 8.05407 14.0011C7.91656 14.0011 7.78236 13.9565 7.66958 13.8732C7.55679 13.79 7.47086 13.6722 7.42335 13.5356L6.61913 11.2329L4.43992 10.3841C4.31088 10.3339 4.19951 10.2429 4.12084 10.1236C4.04218 10.0042 4 9.86224 4 9.71674C4 9.57124 4.04218 9.42924 4.12084 9.3099C4.19951 9.19056 4.31088 9.09963 4.43992 9.04937L6.6204 8.20063L7.42251 5.89518C7.47001 5.75864 7.55595 5.6408 7.66873 5.55756C7.78152 5.47432 7.91572 5.42969 8.05322 5.42969C8.19073 5.42969 8.32493 5.47432 8.43771 5.55756C8.5505 5.6408 8.63644 5.75864 8.68394 5.89518L9.48605 8.20241L11.6648 9.05115C11.7941 9.09994 11.9061 9.18982 11.9853 9.30848C12.0644 9.42714 12.1071 9.56879 12.1073 9.71406Z"
        fill="#B366FF"
      />
    </g>
    <g filter="url(#filter1_d_13323_38433)">
      <path
        d="M15.2807 6.18682C15.2811 6.26119 15.2597 6.33387 15.2195 6.39488C15.1793 6.45588 15.1222 6.5022 15.0561 6.52746L13.9432 6.96067L13.5338 8.1374C13.5095 8.2071 13.4657 8.26725 13.4081 8.30973C13.3505 8.35222 13.282 8.375 13.2118 8.375C13.1417 8.375 13.0732 8.35222 13.0156 8.30973C12.958 8.26725 12.9142 8.2071 12.8899 8.1374L12.4794 6.96203L11.3671 6.52882C11.3013 6.50317 11.2444 6.45676 11.2043 6.39584C11.1641 6.33493 11.1426 6.26245 11.1426 6.18818C11.1426 6.11392 11.1641 6.04144 11.2043 5.98053C11.2444 5.91961 11.3013 5.8732 11.3671 5.84754L12.4801 5.41433L12.8895 4.2376C12.9137 4.1679 12.9576 4.10775 13.0152 4.06527C13.0727 4.02278 13.1412 4 13.2114 4C13.2816 4 13.3501 4.02278 13.4077 4.06527C13.4652 4.10775 13.5091 4.1679 13.5333 4.2376L13.9427 5.41525L15.0548 5.84846C15.1208 5.87336 15.178 5.91923 15.2184 5.9798C15.2588 6.04037 15.2806 6.11267 15.2807 6.18682Z"
        fill="#B366FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13323_38433"
        x="0"
        y="1.42969"
        width="16.1074"
        height="16.5703"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.97194 0 0 0 0 0.886877 0 0 0 0 0.687239 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13323_38433"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13323_38433"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_13323_38433"
        x="7.14258"
        y="0"
        width="12.1387"
        height="12.375"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.97194 0 0 0 0 0.886877 0 0 0 0 0.687239 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13323_38433"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13323_38433"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
