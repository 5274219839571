export const HourGlassClockSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13300_52287)">
      <circle
        cx="10.7192"
        cy="3.28125"
        r="2.40625"
        stroke="#444444"
        strokeWidth="0.875"
      />
      <path
        d="M10.5005 2.1875V3.5H11.813"
        stroke="#444444"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.875488 2.625H8.31299M0.875488 13.125H11.3755M12.2505 13.125H11.3755M2.18799 3.0625C2.18799 4.52083 3.06299 7.4375 6.56299 7.4375C8.38246 7.4375 9.49254 6.64929 10.1391 5.6875M2.18799 12.6875C2.18799 11.375 3.04553 8.3125 6.56299 8.3125C10.063 8.3125 11.2297 11.375 11.3755 13.125"
        stroke="#444444"
        strokeWidth="0.875"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13300_52287">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const WaitingTimeClockSVG = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13300_52308)">
      <path
        d="M6.96644 0.609375C6.72482 0.609375 6.52894 0.80525 6.52894 1.04688C6.52894 1.2885 6.72482 1.48438 6.96644 1.48438V0.609375ZM7.32956 3.42684C7.32956 3.18521 7.13368 2.98934 6.89206 2.98934C6.65043 2.98934 6.45456 3.18521 6.45456 3.42684H7.32956ZM6.89206 6.99684H6.45456C6.45456 7.13281 6.51777 7.26105 6.62562 7.34385L6.89206 6.99684ZM9.23454 9.34701C9.42619 9.49416 9.70084 9.45809 9.84799 9.26644C9.99514 9.07479 9.95906 8.80014 9.76742 8.65299L9.23454 9.34701ZM2.89619 10.7146C2.73318 10.5362 2.45645 10.5238 2.27809 10.6868C2.09973 10.8498 2.08729 11.1265 2.2503 11.3049L2.89619 10.7146ZM12.479 6.99688C12.479 10.0413 10.0109 12.5094 6.96644 12.5094V13.3844C10.4942 13.3844 13.354 10.5246 13.354 6.99688H12.479ZM6.96644 1.48438C10.0109 1.48438 12.479 3.95241 12.479 6.99688H13.354C13.354 3.46916 10.4942 0.609375 6.96644 0.609375V1.48438ZM6.45456 3.42684V6.99684H7.32956V3.42684H6.45456ZM6.62562 7.34385L9.23454 9.34701L9.76742 8.65299L7.15849 6.64983L6.62562 7.34385ZM6.96644 12.5094C5.35457 12.5094 3.90477 11.8181 2.89619 10.7146L2.2503 11.3049C3.41768 12.5822 5.09869 13.3844 6.96644 13.3844V12.5094Z"
        fill="#444444"
      />
      <path
        d="M7.00127 1.04688C3.71518 1.04688 1.05127 3.71078 1.05127 6.99688C1.05127 8.58137 1.67063 10.0212 2.68045 11.0875"
        stroke="#444444"
        strokeWidth="0.875"
        strokeLinecap="round"
        strokeDasharray="0.88 1.75"
      />
    </g>
    <defs>
      <clipPath id="clip0_13300_52308">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ChevronNewSVG = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.40625 3L9.90625 7.5L5.40625 12"
      stroke="#8000FF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
